<template>
  <div>
    <!-- user searching  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Search &amp; manage gift codes
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          solo dense hide-details flat
          class="rounded-0 text-caption mr-2"
          placeholder="Search by code"
          background-color="grey800"
        />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading.list"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        item-key="code"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :expanded="expanded"
        :loading="loading.list"
        @pagination="onPagination"
      >
        <template #item.code="{ item, value }">
          <span class="rounded-0 text-caption py-1 px-2 green300--text green800" v-text="value" />
        </template>

        <template #item.maxUses="{ value, item }">
          <h4 class="text-body-2 green200--text lh-1-4">
            {{ value | toLocaleInt }}
            <span class="text--secondary text-caption">({{ item.usesLeft }} left)</span>
          </h4>
        </template>

        <template #item.amount="{ value }">
          <scrap :amount="value" />
        </template>

        <template #item.lastUpdatedAt="{ value, item }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
            </template>
            <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'pencil']"
            class="primary500--text mr-2 link"
            @click="onExpandItem(item)"
          />

          <fai
            :icon="['fad', 'trash']"
            class="primary500--text link"
            @click="onDelete(item)"
          />
        </template>

        <!-- transaction info -->
        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 data-detail">
            <AdminGiftCodeForm
              :value="item"
              :loading="loading.create"
              @submit="onSetGiftCode"
            />
          </td>
        </template>
      </v-data-table>
    </section>

    <!-- create meme -->
    <section class="mb-6">
      <v-expansion-panels accordion flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header color="grey600">
            Create new code
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminGiftCodeForm
              v-model="newGiftCodeForm"
              :loading="loading.create"
              is-new
              @submit="onSetGiftCode"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>

    <section>
      <v-expansion-panels accordion flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header color="grey600">
            Generate giftcards
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminGiftCardForm
              v-model="newGiftCardForm"
              :loading="loading.generate"
              @submit="onGenerateGiftcards"
            />

            <div v-if="giftcards.length" class="mt-3 pa-6 grey700">
              <div class="d-flex justify-end mb-3">
                <v-btn
                  color="blue700 blue300--text"
                  class="rounded-0 mr-2"

                  depressed :ripple="false" x-small
                  @click="toUppercase = !toUppercase"
                >
                  Uppercase
                </v-btn>
                <v-btn
                  color="blue700 blue300--text"
                  class="rounded-0"
                  depressed :ripple="false" x-small
                  @click="onCopy(parsedGiftcards.join(','))"
                >
                  Copy CSV
                </v-btn>
              </div>
              <pre class="text-body-2" v-text="parsedGiftcards.join('\n')" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'

import AdminGiftCodeForm from './components/AdminGiftCodeForm'
import AdminGiftCardForm from './components/AdminGiftCardForm'

const newGiftCodeDefault = () => ({
  code: null,
  maxUses: null,
  usesLeft: null,
  amount: null,
  data: {},
})

const newGiftCardDefault = () => ({
  prefix: null,
  count: null,
  amount: null,
})

export default {
  components: { AdminGiftCodeForm, AdminGiftCardForm },
  data() {
    return {
      loading: {
        list: false,
        create: false,
        generate: false,
      },
      total: 0,
      expanded: [],
      search: '',
      options: {
        sortBy: ['lastUpdatedAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Code', value: 'code', align: 'start' },
        {
          text: 'Amount', value: 'amount', align: 'start',
        },
        {
          text: 'Uses', value: 'maxUses', align: 'start',
        },
        {
          text: 'Last Updated', value: 'lastUpdatedAt', align: 'end',
        },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      newGiftCodeForm: newGiftCodeDefault(),
      newGiftCardForm: newGiftCardDefault(),
      toUppercase: false,
      giftcards: [],
      entries: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    parsedGiftcards() {
      if (this.toUppercase) {
        return this.giftcards.map(i => i.toUpperCase())
      }

      return this.giftcards
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.onSearchInput(val)
      },
      deep: true,
    },
  },
  methods: {
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item.code === expandedItem?.code) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    onSearchInput: debounce(function fn(val) {
      this.fetchApiData()
    }, 500),
    async fetchApiData() {
      this.loading.list = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.codes.list', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          search: this.search,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async onSetGiftCode(form) {
      this.loading.create = true

      try {
        const giftcode = await this.$socket.request('admin.codes.set', form)

        this.newGiftCodeForm = newGiftCodeDefault()
        this.$toast.success(`Code "${giftcode.code}" set successfully.`)

        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onGenerateGiftcards(form) {
      this.loading.generate = true

      try {
        const giftcards = await this.$socket.request('admin.giftcards.generate', form)

        this.newGiftCodeForm = newGiftCardDefault()
        this.giftcards = giftcards
        this.$toast.success('Giftcards generated successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.generate = false
      }
    },
    async onDelete({ code }) {
      this.loading.create = true

      try {
        await this.$socket.request('admin.codes.delete', code)

        this.$toast.success(`Code "${code}" deleted successfully.`)
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onCopy(text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$toast.info('Copied')
      } catch (err) {
        this.$toast.error('Copy failed, sorry.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
