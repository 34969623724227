<template>
  <v-row class="pt-4">
    <v-col cols="12" md="12">
      <v-text-field
        v-model="form.code"
        :disabled="!isNew"
        solo dense flat
        placeholder="Code (4-15 chars) or empty to generate"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.number="form.amount"
        type="number"
        solo dense flat
        placeholder="Amount in scrap"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.number="form.maxUses"
        type="number"
        solo dense flat
        placeholder="Max uses"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.number="form.usesLeft"
        type="number"
        solo dense flat
        placeholder="(Uses left)"
        hide-details
        background-color="grey600"
      />
    </v-col>


    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.data.discordRole"
            solo dense flat
            placeholder="Discord role ID"
            hide-details
            background-color="grey600"
          />
        </v-col>
        <v-col cols="12" md="8" class="text-caption text--secondary">
          You can find this by right-clicking the role --> Copy ID with Developer mode.
          <div class="text-body-2 white--text">
            Booster role: <code>666385316621451304</code>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="6" class="primary300--text d-flex align-center">
      Total Value: ${{ totalValue / 100 | toCurrency }}
    </v-col>
    <v-col cols="6" class="d-flex justify-end">
      <v-btn
        class="rounded-0 font-weight-bold"
        color="blue700 blue300--text"
        :ripple="false"
        :loading="loading"
        @click="onSubmit"
      >
        <fai :icon="['fas', 'check']" class="mr-2" />
        {{ isNew ? 'Create' : 'Update' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        data: {},
        ...this.value,
      },
    }
  },
  computed: {
    totalValue() {
      const total = this.form.amount * (this.form.usesLeft || this.form.maxUses)

      return Number.isInteger(total) ? total : 0
    },
  },
  watch: {
    value(val) {
      if (JSON.stringify(val) === JSON.stringify(this.form)) return

      this.form = { data: {}, ...val }
    },
    form: {
      handler(val) {
        if (val.usesLeft > val.maxUses) {
          this.form.usesLeft = val.maxUses
        }

        this.$emit('input', { ...val })
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>
