<template>
  <v-row class="pt-4">
    <v-col cols="12" md="4">
      <v-text-field
        v-model="form.prefix"
        solo dense flat
        placeholder="Prefix (ex: KIN250) or empty"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.number="form.amount"
        type="number"
        solo dense flat
        placeholder="Amount in scrap (integer)"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model.number="form.count"
        type="number"
        solo dense flat
        placeholder="Count (how many codes)"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn
        class="rounded-0 font-weight-bold"
        color="blue700 blue300--text"
        :ripple="false"
        :loading="loading"
        @click="onSubmit"
      >
        <fai :icon="['fas', 'check']" class="mr-2" />
        Gerenate
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: { ...this.value },
    }
  },
  watch: {
    value(val) {
      if (JSON.stringify(val) === JSON.stringify(this.form)) return

      this.form = { ...val }
    },
    form: {
      handler(val) {
        this.$emit('input', { ...val })
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>
